export function limitText(text, maxChars) {
  if (text.length >= maxChars) {
    return text.substring(0, maxChars) + "…";
  }
  return text;
}

export function zeroPad(num, places) {
  return String(num).padStart(places, "0");
}
