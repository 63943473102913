import React, { useEffect, useState } from "react";
import "./speakers.css";
import iconPonente from "../../../assets/img/ponente.svg";
import imgChevron from "../../../assets/img/chevron.svg";

import { getPonentes } from "../../../services/ponenteService";
import SpeakerCard from "./SpeakerCard";

const NUM_MINIMIZED_ELEMENTS = 4;

function PonentesSection() {
  const [ponentes, setPonentes] = useState([]);
  const [isToggled, setIsToggled] = useState(false);

  const fetchPonentes = async (showAll) => {
    const ponentesResponse = await getPonentes();
    if (showAll) {
      setPonentes(ponentesResponse);
    } else {
      setPonentes(ponentesResponse.slice(0, NUM_MINIMIZED_ELEMENTS));
    }
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    fetchPonentes(isToggled);
  }, [isToggled]);

  return (
    <div id="ponentes">
      <div className="container position-relative pt-5 pb-3">
        <div className="row pt-3">
          <div className="col text-center">
            <img src={iconPonente} alt="" className="d-inline icon" />
            <h2 className="d-inline">Conoce a los ponentes</h2>
          </div>
        </div>
        <div className="cards row g-4">
          {ponentes?.map((ponente) => (
            <div className="col-12 col-sm-6 col-lg-3" key={ponente.id}>
              <SpeakerCard ponente={ponente} icon={ponente.img} />
            </div>
          ))}
        </div>
        <div className="row">
          <img
            alt=""
            src={imgChevron}
            role="button"
            className={"mt-4 arrow " + (isToggled ? "toggled" : "")}
            onClick={handleToggle}
          />
        </div>
      </div>
    </div>
  );
}

export default PonentesSection;
