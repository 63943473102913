import React from "react";
import ConferencesSection from "./conferences/ConferencesSection";
import CounterSection from "./counter/CounterSection";
import EquipoSection from "./equipo/EquipoSection";
import HeroSection from "./hero/HeroSection";
import InvitationSection from "./invitation/InvitationSection";
import PatrocinadoresSection from "./patrocinadores/PatrocinadoresSection";
import SpeakersSection from "./speakers/SpeakersSection";

function HomePage() {
  return (
    <>
      <HeroSection />
      <InvitationSection />
      <ConferencesSection id="conferences" />
      <SpeakersSection id="ponentes" />
      <EquipoSection />
      <PatrocinadoresSection />
      <CounterSection />
    </>
  );
}

export default HomePage;
