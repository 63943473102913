import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import SpeakersPage from "./pages/speakers/SpeakersPage";
import CalendarPage from "./pages/calendar/CalendarPage";
import RegisterPage from "./pages/register/RegisterPage";
import NotFoundComponent from "./pages/not-found/NotFoundComponent";
import ConferencesPage from "./pages/conferences/ConferencesPage";

const hideRegistro = process.env.REACT_APP_HIDE_REGISTRO;

function PageRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route path="/ponente/:id">
        <SpeakersPage />
      </Route>
      <Route path="/conferencia/:id">
        <ConferencesPage />
      </Route>
      <Route path="/calendario">
        <CalendarPage />
      </Route>
      {hideRegistro ? null : (
        <Route path="/registro">
          <RegisterPage />
        </Route>
      )}
      <Route path="**">
        <NotFoundComponent />
      </Route>
    </Switch>
  );
}

export default PageRouter;
