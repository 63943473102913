import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import "./speakers.css";
import iconfacebook from "../../assets/img/facebook.png";
import iconlinkedin from "../../assets/img/linkedin.png";
import iconDev from "../../assets/img/dev.svg";
import { useParams } from "react-router-dom";
import { getPonenteById } from "../../services/ponenteService";
import ConferenceCard from "../home/conferences/ConferenceCard";

function SpeakersPage({ history }) {
  const { id } = useParams();

  const [speaker, setSpeaker] = useState({});

  const fetchSpeakers = useCallback(async () => {
    const result = await getPonenteById(id);
    if (!result) {
      history.push("/");
    }
    setSpeaker(result);
  }, [id, history]);

  useEffect(() => {
    fetchSpeakers();
  }, [fetchSpeakers]);

  const gotSocial = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div id="speakers" className="container">
      <div className="row">
        <div className="photo col-8 offset-2 offset-md-0 mb-4 col-md-4">
          <img src={speaker?.img} className="person" alt={speaker?.name} />
          <div className="social">
            {speaker.social ? (
              speaker.social[0].type === "facebook" && speaker.social[0].url ? (
                <img
                  onClick={() => gotSocial(speaker.social[0].url)}
                  src={iconfacebook}
                  className="social-icon"
                  alt="Facebook"
                />
              ) : null
            ) : null}
            {speaker.social ? (
              speaker.social[1].type === "linked" && speaker.social[1].url ? (
                <img
                  onClick={() => gotSocial(speaker.social[1].url)}
                  src={iconlinkedin}
                  className="social-icon"
                  alt="LinkedIn"
                />
              ) : null
            ) : null}
          </div>
        </div>
        <div className="info col-12 col-md-8">
          <label className="title mb-2 text-center text-md-start">
            {speaker?.name}
          </label>
          <label
            className="text mb-4"
            dangerouslySetInnerHTML={{ __html: speaker?.description }}
          />
          {speaker.conferences?.length > 0 ? (
            <>
              <label className="subtitle">Conferencias</label>
              <div className="cards row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {speaker.conferences.map((conference) => (
                  <div className="card-conf col" key={conference.id}>
                    <ConferenceCard conference={conference} icon={iconDev} />
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SpeakersPage);
