import conferences from "../static/conferences.json";
import { getPonenteById } from "./ponenteService";

export async function getConferences() {
  return conferences;
}

export async function getConferenceById(id) {
  const conference = conferences.find((ponente) => ponente.id === parseInt(id));
  conference.speakerDetails = await getPonenteById(conference.speaker);
  return conference;
}

function sortConferencesByDayAndHour(conf1, conf2) {
  if (conf1.day !== conf2.day) return conf1.day - conf2.day;

  return parseInt(conf1.hour) - parseInt(conf2.hour);
}

async function getConferencesByDay(day) {
  return conferences
    .filter((conf) => conf.day === day)
    .sort(sortConferencesByDayAndHour)
    .map((conf) => {
      getPonenteById(conf.speaker).then(
        (speaker) => (conf.speakerDetails = speaker)
      );
      return conf;
    });
}

export async function getConferencesWithDays() {
  let daysConferences = {
    ocho: [],
    nueve: [],
    diez: [],
    once: [],
    doce: [],
  };
  daysConferences.ocho = await getConferencesByDay(8);
  daysConferences.nueve = await getConferencesByDay(9);
  daysConferences.diez = await getConferencesByDay(10);
  daysConferences.once = await getConferencesByDay(11);
  daysConferences.doce = await getConferencesByDay(12);
  return daysConferences;
}
