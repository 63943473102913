import ponentes from "../static/ponentes.json";
import { getConferences } from "./conferencesService";

export async function getPonentes() {
  return ponentes;
}

export async function getPonenteById(id) {
  const ponente = ponentes.find((ponente) => ponente.id === parseInt(id));
  ponente.conferences = (await getConferences()).filter(
    (x) => x.speaker === ponente.id
  );
  return ponente;
}
