import React, { useCallback, useEffect, useState } from "react";
import "./counter.css";
import { getCount } from "../../../services/counterService";
import { zeroPad } from "../../../utils";

function CounterSection() {
  const [counter, setCounter] = useState(0);

  const getCounter = useCallback(async () => {
    setCounter(await getCount());
  }, [setCounter]);

  useEffect(() => {
    getCounter();
  }, [getCounter]);

  return (
    <div id="counter">
      <div className="container position-relative pb-5">
        <div className="row py-3">
          <div className="col text-center">
            <h2 className="d-inline">Visitantes</h2>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around">
          <p className="view-counter">{zeroPad(counter, 5)}</p>
        </div>
      </div>
    </div>
  );
}

export default CounterSection;
