import React, { useEffect, useState } from "react";
import "./conferences.css";
import iconPresentation from "../../../assets/img/presentation.svg";
import iconDev from "../../../assets/img/dev.svg";
import ConferenceCard from "./ConferenceCard";
import { getConferences } from "../../../services/conferencesService";

function ConferencesSection() {
  const [conferences, setConferences] = useState([]);

  const fetchConferences = async () => setConferences(await getConferences());

  useEffect(() => fetchConferences(), []);

  return (
    <div id="conferences">
      <div className="container position-relative py-5">
        <div className="row pt-3">
          <div className="col text-center">
            <img src={iconPresentation} alt="" className="d-inline icon" />
            <h2 className="d-inline">Conferencias</h2>
          </div>
        </div>
        <div className="cards row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
          {conferences?.map((conference) => (
            <div className="col" key={conference.id}>
              <ConferenceCard conference={conference} icon={iconDev} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ConferencesSection;
