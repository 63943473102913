import React from "react";
import "./equipo.css";
import iconEquipo from "../../../assets/img/organiza.svg";
import iconEstudiantes from "../../../assets/img/estudiantes.svg";
import iconCarreras from "../../../assets/img/carreras.svg";
import imgEquipo from "../../../assets/img/EquipoCongreso.jpg";

const CARRERAS = 4;
const ESTUDIANTES = 20;

function EquipoSection() {
  return (
    <div id="equipo">
      <div className="container py-2 py-lg-5">
        <div className="row pt-3">
          <div className="col text-center"></div>
        </div>
        <div className="row py-4 px-2">
          <div className="d-none d-lg-flex col-lg-6 equipo-image">
            <img src={imgEquipo} alt="" />
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-end my-5">
            <div className="text-center text-lg-end">
              <img src={iconEquipo} alt="" className="d-inline icon" />
              <h2 className="d-inline">Equipo Organizador</h2>
            </div>
            <p className="tagline mt-4 text-center text-lg-end">
              Gestionado por alumnos para alumnos
            </p>

            <div className="d-flex flex-row justify-content-end align-items-center my-3">
              <div className="d-flex flex-column align-items-center push">
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <img src={iconEstudiantes} alt="" className="equipo-icon" />
                  <h3 className="number">{ESTUDIANTES}</h3>
                </div>
                <p className="number-tag">estudiantes</p>
              </div>

              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <img src={iconCarreras} alt="" className="equipo-icon" />
                  <h3 className="number">{CARRERAS}</h3>
                </div>
                <p className="number-tag">carreras</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipoSection;
