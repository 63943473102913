import React from "react";
import "./footer.css";
import imgLogo from "../../assets/img/logo.jpg";

function FooterSection() {
  return (
    <div id="footer">
      <div className="content justify-content-center">
        <div className="row">
          <div className="item col d-none d-md-block">
            <img className="logo" src={imgLogo} alt="" />
          </div>
          <div className="item col">
            <label className="title">Enlaces</label>
            <a
              className="text link"
              href="https://www.ceti.mx/cetinew/articulo.php?id=48&Itemid=53"
              target="_blank"
              rel="noopener noreferrer"
            >
              Conoce el CETI
            </a>
          </div>
          <div className="item col">
            <label className="title">Contacto</label>
            <label className="text">Calle Nueva Escocia 1885,</label>
            <label className="text">Guadalajara, Jal. México.</label>
            <label className="text">33 3641 3250</label>
            <label className="text">direccion.plantel.col@ceti.mx</label>
          </div>
        </div>
      </div>
      <div className="vector-mexico"></div>
    </div>
  );
}

export default FooterSection;
