import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./conferences.css";
import iconCalendar from "../../assets/img/Calendar.svg";
import { useParams } from "react-router-dom";
import { getConferenceById } from "../../services/conferencesService";
import { getPonenteById } from "../../services/ponenteService";

function ConferencesPage({ history }) {
  const { id } = useParams();

  const [conference, setConference] = useState({});
  const [speaker, setSpeaker] = useState({});

  const fetchPonenteInfo = useCallback(
    async (speakerId) => {
      setSpeaker(await getPonenteById(speakerId));
    },
    [setSpeaker]
  );

  const fetchConferenceInfo = useCallback(async () => {
    const result = await getConferenceById(id);
    if (!result) {
      history.push("/");
    }
    setConference(result);
    fetchPonenteInfo(result.speaker);
  }, [id, history, fetchPonenteInfo]);

  useEffect(() => {
    fetchConferenceInfo();
  }, [fetchConferenceInfo]);

  return (
    <div id="conference" className="container">
      <div className="row">
        <div className="info col-md-8 col-sm-12">
          <label className="title mb-2">{conference.title}</label>
          <div className="data">
            <label>
              <img src={iconCalendar} alt="" className="d-inline icon me-1" />
              {conference.day} de Noviembre - {conference.hour}
            </label>
          </div>
          <label className="text mb-4">{conference.description}</label>
        </div>
        {speaker ? (
          <div className="photo col-md-4 col-sm-12">
            <label className="title">Impartida por</label>
            <img className="person" src={speaker.img} alt={speaker.name} />
            <label className="name">{speaker.name}</label>
            <Link className="link" to={"/ponente/" + speaker.id}>
              Ver más
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ConferencesPage;
