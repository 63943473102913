import { Link } from "react-router-dom";

let color = [
  { background: "var(--color-accent)" },
  { background: "var(--color-accent)" },
  { background: "var(--color-accent-light)" },
  { background: "var(--color-triadic)" },
  { background: "var(--color-triadic-light)" },
  { background: "var(--color-gray-dark)" },
];
function getColor() {
  return color[Math.floor(Math.random() * (0 - 5) + 0) * -1];
}

function DayCard({ conference }) {
  console.log(conference);
  return (
    <div className="dayCard">
      <div className="hour" style={getColor()}>
        {conference.hour}
      </div>
      <div className="day">
        <div className="title">{conference.title}</div>
        <div className="text mb-2 mt-2">{conference.speakerDetails?.name}</div>
        <Link className="link" to={"/conferencia/" + conference.id}>
          Saber más
        </Link>
      </div>
    </div>
  );
}

export default DayCard;
