import React from "react";
import { Link } from "react-router-dom";
import { limitText } from "../../../utils";

function PonenteCard({ ponente, icon }) {
  return (
    <div className="ponente-card d-flex flex-column align-items-center mt-4 mb-3">
      <img src={icon} alt={ponente.name} className="img" />
      <h3 className="name pt-2"> {ponente.name}</h3>
      <p
        className="text-center px-4 mb-2"
        dangerouslySetInnerHTML={{ __html: limitText(ponente.description, 42) }}
      />
      <Link to={`/ponente/${ponente.id}`} className="link">
        Ver más
      </Link>
    </div>
  );
}

export default PonenteCard;
