import { BrowserRouter } from "react-router-dom";
import "./App.css";
import PageRouter from "./Routes";
import HeaderSection from "./pages/header/HeaderSection";
import FooterSection from "./pages/footer/FooterSection";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL || "/"}>
      <HeaderSection />
      <PageRouter />
      <div className="spacer" />
      <FooterSection />
    </BrowserRouter>
  );
}

export default App;
