import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function ConferenceCard({ conference, icon }) {
  return (
    <Link
      to={`/conferencia/${conference.id}`}
      style={{ textDecoration: "none " }}
    >
      <Card className="h-100">
        {icon && <img src={icon} alt="" className="card-img" />}
        <p className="card-title my-2">{conference.title}</p>
      </Card>
    </Link>
  );
}

export default ConferenceCard;
